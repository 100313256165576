import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SelectValue } from './interfaces/select-value';

@Component({
  selector: 'app-modal-select',
  templateUrl: './modal-select.component.html',
  styleUrls: ['./modal-select.component.scss']
})
export class ModalSelectComponent implements OnInit {
  @Input() header: string;
  @Input() headerIcon: string;
  @Input() optionIcon: string;
  @Input() alternativeIcon: string;
  @Input() multipleSelection = false;
  @Input() twoDataTypes = false;
  @Input() sectionHeaders: string[];

  @Input() selectedElement: SelectValue | SelectValue[];
  @Input() elements: SelectValue[] | SelectValue[][];
  @Output() cancelEmitter = new EventEmitter<any>();
  @Output() confirmEmitter = new EventEmitter<SelectValue[]>();

  selectedButton = 0;
  currentElement: SelectValue;

  selectedButtons: number[] = [];
  selectedElements: SelectValue[] = [];

  selectedButtonDataType1 = 0;
  selectedButtonDataType2 = 0;
  currentDataType1Element: SelectValue;
  currentDataType2Element: SelectValue;

  ngOnInit(): void {
    if (this.selectedElement) {
      if (!this.twoDataTypes) {
        this.selectedButton = this.elements.findIndex(
          x => x.id === (this.selectedElement as SelectValue).id
        );
        this.currentElement = this.elements[this.selectedButton] as SelectValue;
      } else {
        this.selectedButtonDataType1 = (this
          .elements as SelectValue[][])[0].findIndex(
          x => x.id === (this.selectedElement as SelectValue[])[0]?.id
        );
        this.currentDataType1Element = this.elements[0][
          this.selectedButtonDataType1
        ];
        this.selectedButtonDataType2 = (this
          .elements as SelectValue[][])[1].findIndex(
          x => x.id === (this.selectedElement as SelectValue[])[1]?.id
        );
        this.currentDataType2Element = this.elements[1][
          this.selectedButtonDataType2
        ];
      }
    }
  }

  onCancel() {
    this.cancelEmitter.emit();
  }

  onConfirm() {
    if (!this.twoDataTypes) {
      this.confirmEmitter.emit(
        (this.multipleSelection
          ? this.selectedElements
          : [this.currentElement]) as SelectValue[]
      );
    } else {
      this.confirmEmitter.emit([
        this.currentDataType1Element,
        this.currentDataType2Element
      ]);
    }
    this.onCancel();
  }

  onClick(e: any) {
    if (this.twoDataTypes) {
      if (e.dataSetId === 'ds1' && e.index !== this.selectedButtonDataType1) {
        this.selectedButtonDataType1 = e.index;
        this.currentDataType1Element = this.elements[0][e.index];
      } else if (
        e.dataSetId === 'ds2' &&
        e.index !== this.selectedButtonDataType2
      ) {
        this.selectedButtonDataType2 = e.index;
        this.currentDataType2Element = this.elements[1][e.index];
      }
    } else if (this.multipleSelection) {
      const i = this.selectedButtons.findIndex(ind => ind === e);
      if (i < 0) {
        this.selectedButtons.push(e);
        this.selectedElements.push(this.elements[e] as SelectValue);
      } else {
        this.selectedButtons.splice(i, 1);
        this.selectedElements.splice(i, 1);
      }
    } else {
      if (e !== this.selectedButton) {
        this.selectedButton = e;
        this.currentElement = this.elements[this.selectedButton] as SelectValue;
      }
    }
  }

  findInSelectedButtons(i: number): number {
    return this.selectedButtons.find(ind => ind === i) as number;
  }
}
